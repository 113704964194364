import React, { Component } from 'react';
import { auth } from '../../../firebase';
import Sidebar from '../../Sidebar';
import Navbar from '../../Navbar';
import { Modal } from 'react-bootstrap';
import { Card, CardActionArea, CardContent, Typography, CardMedia } from '@mui/material';
import './minatrinidad.css';
import 'animate.css';

class MinaTrinidad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleSidebar: false,
            porcentaje: 25,
            urlImgModal: '',
            acceso: false,
            vista: 'documentos'
        }
    }

    componentDidMount = () => {
        document.title = 'Garteel Clientes';
        auth.onAuthStateChanged( async (user) => {
            if (user) {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ operacion: 'getAcceso', correo: user.email, idProyecto: 33 })
                };
                const response = await fetch('https://clientes.garteel.com/php/DB/inversionistas.php', requestOptions);
                const data = await response.json();
                if (data.error === 0) {
                    if (data.acceso === false) {
                        window.location.href='/';
                    }else{
                        document.title = 'Residencia "Mina Trinidad 49" - Garteel Clientes';
                        this.setState({
                            acceso: true
                        })
                    }
                }else{
                    console.log(data);
                }
            }
        })        
    }

    toggleSidebar = () => {
        this.setState({
            toggleSidebar: !this.state.toggleSidebar
        })
    }

    barraProgreso = (porcentaje) => {
        var gradient = '';
        if (porcentaje < 50) {
            gradient = 'linear-gradient(to right, #2EBF70 ' + porcentaje + '%, white ' + porcentaje + '%)';
        }else{
            gradient = 'linear-gradient(to right, #2EBF70 ' + porcentaje + '%, white ' + (100 - Number(porcentaje)) + '%)';
        }
        return gradient
    }

    porcentaje = (porcentaje) => {
        if (porcentaje < 6) {
            return porcentaje + '%'
        }else{
            return (Number(porcentaje) - 3) + '%'   
        }
    }

    openModalImg = (urlImg) => {
        this.setState({
            showModalImg: true,
            urlImgModal: urlImg
        })
    }

    hideModalImg = () => {
        this.setState({
            showModalImg: false,
            urlImg: ''
        })
    }

    handleDescargarClick = (ruta, nombre) => {
        // Genera un timestamp único para evitar la caché
        const timestamp = new Date().getTime();

        // Agrega el timestamp como parámetro a la URL
        const url = `${ruta}?timestamp=${timestamp}`;

        const username = 'GarteelClientes'; // Reemplaza con tu nombre de usuario
        const password = 'Kmj8CL6z'; // Reemplaza con tu contraseña
    
        // Codifica las credenciales en Base64
        const base64Credentials = btoa(`${username}:${password}`);
    
        // Crea una solicitud de descarga
        const xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
    
        // Configura las credenciales en la solicitud
        xhr.setRequestHeader('Authorization', `Basic ${base64Credentials}`);
    
        // Maneja la respuesta de la solicitud
        xhr.onload = function () {
          if (xhr.status === 200) {
            // La descarga se realizó correctamente, puedes procesar el archivo aquí
            const blob = new Blob([xhr.response], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = nombre; // Nombre del archivo que se descargará
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          } else {
            console.error('Error al descargar el archivo. Código de estado:', xhr.status);
          }
        };
    
        // Envia la solicitud
        xhr.responseType = 'blob';
        xhr.send();
      };

      rutaImagen = (ruta) => {
        const uniqueParam = `?${new Date().getTime()}`;
        return ruta + uniqueParam
      }
    
    render() { 
        return ( 
            <>
            {
                this.state.acceso &&
                <div className='app'>
                    <Sidebar toggledSidebar={this.state.toggleSidebar} toggleSidebar={this.toggleSidebar}/>
                    <main id='main'>
                        <div className="container-fluid h-100 proyecto minaTrinidad p-0">
                            <Navbar toggleSidebar={this.toggleSidebar}/>
                            <div className="principal" style={{ position: 'relative' }}>
                                <img src={"/img/proyectos/33.jpg"} alt="imgProyecto" style={{ height: '100%', width: '100%' }}/>
                                <h1 className='text-center m-0 mt-2 mt-sm-3 mt-md-4 mt-lg-4 mt-xxl-5 tituloProyecto' style={{ color: 'white', textShadow: '0px 0px 25px rgba(0, 0, 0, 1)', fontFamily: 'Julius', position: 'absolute', top: '0', left: '0', right: '0' }}>Residencia "Mina Trinidad 49"</h1>
                                <div className="descripcion px-3" style={{ position: 'absolute', width: '100%' }}>
                                    <div className='p-1 p-sm-1 p-xl-3 p-xxl-4 caracteristicasGenerales' style={{ color: 'white', boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                                        <div className="row">
                                            <div className="col-6 col-xl-3 text-center d-sm-none">
                                                <p className='m-0' style={{ fontFamily: 'Julius' }}><span><i className="bi bi-person-fill"></i></span> Arturo Pérez V.</p>
                                            </div>
                                            <div className="col-6 col-xl-3 text-center d-none d-sm-block">
                                                <p className='m-0' style={{ fontFamily: 'Julius' }}><span><i className="bi bi-person-fill"></i></span> Arturo Pérez Velázquez</p>
                                            </div>
                                            <div className="col-6 col-xl-3 text-center">
                                                <p className='m-0' style={{ fontFamily: 'Julius' }}><span><i className="bi bi-rulers"></i></span> 600 m2</p>
                                            </div>
                                            <div className="col-6 col-xl-3 text-center">
                                                <p className='m-0' style={{ fontFamily: 'Julius' }}><span><i className="bi bi-cone-striped"></i></span> 695 m2</p>
                                            </div>
                                            <div className="col-6 col-xl-3 text-center">
                                                <p className='m-0' style={{ fontFamily: 'Julius' }}><span><i className="bi bi-calendar-check-fill"></i></span> 13 meses</p>
                                            </div>
                                        </div>
                                        <div className="row mt-2 mt-sm-3 mt-md-3 mt-xl-5">
                                            <div className="col-12 text-center">
                                                <p className='m-0 text-white direccion' style={{ fontFamily: 'Julius' }}><span><i className="bi bi-geo-alt-fill"></i></span> Lote 49, Supermanzana 1, Lote Condominal 05, Fraccionamiento Zona Plateada, 42083</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>                            
                            </div>
                            <div className="row m-0 mt-4 mt-sm-0 py-4" style={{ fontFamily: 'Julius', overflowX: 'hidden' }}>
                                <div className="col-sm-4 text-center">
                                    <p className={'m-0 btnSelector ' + (this.state.vista === 'documentos' ? 'zoomed' : '')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ vista: 'documentos' })}>Documentos</p>
                                </div>
                                <div className="col-sm-4 mt-2 mt-sm-0 text-center">
                                    <p className={'m-0 btnSelector ' + (this.state.vista === 'pagos' ? 'zoomed' : '')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ vista: 'pagos' })}>Pagos</p>
                                </div>
                                <div className="col-sm-4 mt-2 mt-sm-0 text-center">
                                    <p className={'m-0 btnSelector ' + (this.state.vista === 'construccion' ? 'zoomed' : '')} style={{ cursor: 'pointer' }} onClick={() => this.setState({ vista: 'construccion' })}>Construcción</p>
                                </div>
                            </div>
                            {
                                this.state.vista === 'documentos' &&
                                    <div className="row m-0 mt-3 pb-5 documentos d-flex justify-content-center animate__animated animate__fadeInUp">
                                        <div className="col-sm-9 col-md-7 col-lg-7 col-xl-6 col-xxl-5">
                                            <ul className='list-group' style={{ boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px' }}>
                                                <li className='list-group-item list-group-item-action d-flex justify-content-between py-1' style={{ borderInline: 'none', borderTop: 'none' }}>
                                                    <div>
                                                        <input className='form-check-input me-1' type="checkbox" checked readOnly/>
                                                        <label htmlFor="form-check-label">Contrato</label>
                                                    </div>
                                                    <div>
                                                        <button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Contrato.pdf", "Contrato.pdf")}>Descargar</button>
                                                    </div>
                                                </li>
                                                <li className='list-group-item list-group-item-action d-flex justify-content-between py-1' style={{ borderInline: 'none', borderTop: 'none' }}>
                                                    <div>
                                                        <input className='form-check-input me-1' type="checkbox" checked readOnly/>
                                                        <label htmlFor="form-check-label">Revisión proyecto</label>
                                                    </div>
                                                    <div>
                                                        <button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Revision-Proyecto.pdf", "Revision_Proyecto.pdf")}>Descargar</button>
                                                    </div>
                                                </li>
                                                <li className='list-group-item list-group-item-action d-flex justify-content-between py-1' style={{ borderInline: 'none', borderTop: 'none' }}>
                                                    <div>
                                                        <input className='form-check-input me-1' type="checkbox" checked readOnly/>
                                                        <label htmlFor="form-check-label">Cuotas de mantenimiento</label>
                                                    </div>
                                                    <div>
                                                        <button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Cuotas-Mantenimiento.pdf", "Cuotas_Mantenimiento.pdf")}>Descargar</button>
                                                    </div>
                                                </li>
                                                <li className='list-group-item list-group-item-action d-flex justify-content-between py-1' style={{ borderInline: 'none', borderTop: 'none' }}>
                                                    <div>
                                                        <input className='form-check-input me-1' type="checkbox" checked readOnly/>
                                                        <label htmlFor="form-check-label">Libertad de gravamen</label>
                                                    </div>
                                                    <div>
                                                        <button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Certificado-Gravamenes.pdf", "Libertad_De_Gravamen.pdf")}>Descargar</button>
                                                    </div>
                                                </li>
                                                <li className='list-group-item list-group-item-action d-flex justify-content-between py-1' style={{ borderInline: 'none', borderTop: 'none' }}>
                                                    <div>
                                                        <input className='form-check-input me-1' type="checkbox" checked readOnly/>
                                                        <label htmlFor="form-check-label">Reglamento de construcción</label>
                                                    </div>
                                                    <div>
                                                        <button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Reglamento-Construccion.pdf", "Reglamento_De_Construccion.pdf")}>Descargar</button>
                                                    </div>
                                                </li>
                                                <li className='list-group-item list-group-item-action d-flex justify-content-between py-1' style={{ borderInline: 'none' }}>
                                                    <div>
                                                        <input className='form-check-input me-1' type="checkbox" checked readOnly/>
                                                        <label htmlFor="form-check-label">Alineamiento y No. Oficial</label>
                                                    </div>
                                                    <div>
                                                        <button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Alineamiento-Numero-Oficial.pdf", "Alineamiento_ y_numero_oficial.pdf")}>Descargar</button>
                                                    </div>
                                                </li>
                                                <li className='list-group-item list-group-item-action d-flex justify-content-between py-1' style={{ borderInline: 'none' }}>
                                                    <div>
                                                        <input className='form-check-input me-1' type="checkbox" checked readOnly/>
                                                        <label htmlFor="form-check-label">Vo. Bo. de colonos</label>
                                                    </div>
                                                    <div>
                                                        <button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Visto-Bueno-Colonos.pdf", "Visto_ bueno_de_colonos.pdf")}>Descargar</button>
                                                    </div>
                                                </li>
                                                <li className='list-group-item list-group-item-action py-1' style={{ borderInline: 'none' }}>
                                                    <input className='form-check-input me-1' type="checkbox" checked={false} readOnly/>
                                                    <label htmlFor="form-check-label">Uso de suelo</label>
                                                </li>
                                                <li className='list-group-item list-group-item-action d-flex justify-content-between py-1' style={{ borderInline: 'none' }}>
                                                    <div>
                                                        <input className='form-check-input me-1' type="checkbox" checked readOnly/>
                                                        <label htmlFor="form-check-label">Contrato CAASIM</label>
                                                    </div>
                                                    <div>
                                                        <button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Contrato-CAASIM.pdf", "Contrato_CAASIM.pdf")}>Descargar</button>
                                                    </div>
                                                </li>
                                                <li className='list-group-item list-group-item-action d-flex justify-content-between py-1' style={{ borderInline: 'none' }}>
                                                    <div>
                                                        <input className='form-check-input me-1' type="checkbox" checked readOnly/>
                                                        <label htmlFor="form-check-label">Pagaré de incio de obra</label>
                                                    </div>
                                                    <div>
                                                        <button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Pagare-Inicio-Obra.pdf", "Pagare_inicio_obra.pdf")}>Descargar</button>
                                                    </div>
                                                </li>
                                                <li className='list-group-item list-group-item-action d-flex justify-content-between py-1' style={{ borderInline: 'none' }}>
                                                    <div>
                                                        <input className='form-check-input me-1' type="checkbox" checked readOnly/>
                                                        <label htmlFor="form-check-label">Fianza de incio de obra</label>
                                                    </div>
                                                    <div>
                                                        <button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Fianza-Inicio-Obra.pdf", "Fianza_inicio_obra.pdf")}>Descargar</button>
                                                    </div>
                                                </li>
                                                <li className='list-group-item list-group-item-action py-1' style={{ borderInline: 'none' }}>
                                                    <input className='form-check-input me-1' type="checkbox" checked={false} readOnly/>
                                                    <label htmlFor="form-check-label">Contrato CFE</label>
                                                </li>
                                                <li className='list-group-item list-group-item-action d-flex justify-content-between py-1' style={{ borderInline: 'none' }}>
                                                    <div>
                                                        <input className='form-check-input me-1' type="checkbox" checked readOnly/>
                                                        <label htmlFor="form-check-label">Pago licencia de construcción</label>
                                                    </div>
                                                    <div>
                                                        <button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Pago-Licencia-Construccion.pdf", "Pago_licencia_construccion.pdf")}>Descargar</button>
                                                    </div>
                                                </li>
                                                <li className='list-group-item list-group-item-action d-flex justify-content-between py-1' style={{ borderInline: 'none' }}>
                                                    <div>
                                                        <input className='form-check-input me-1' type="checkbox" checked readOnly/>
                                                        <label htmlFor="form-check-label">Autorización de modificación de obra</label>
                                                    </div>
                                                    <div>
                                                        <button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Autorizacion-Modificacion-Obra.pdf", "Autorizacion_modificacion_obra.pdf")}>Descargar</button>
                                                    </div>
                                                </li>
                                                <li className='list-group-item list-group-item-action py-1' style={{ borderInline: 'none' }}>
                                                    <input className='form-check-input me-1' type="checkbox" checked={false} readOnly/>
                                                    <label htmlFor="form-check-label">Licencia de construcción</label>
                                                </li>
                                                <li className='list-group-item list-group-item-action py-1' style={{ borderInline: 'none' }}>
                                                    <input className='form-check-input me-1' type="checkbox" checked={false} readOnly/>
                                                    <label htmlFor="form-check-label">Terminación de obra</label>
                                                </li>
                                                <li className='list-group-item list-group-item-action py-1' style={{ borderInline: 'none' }}>
                                                    <input className='form-check-input me-1' type="checkbox" checked={false} readOnly/>
                                                    <label htmlFor="form-check-label">Acta entrega - recepción</label>
                                                </li>
                                                <li className='list-group-item list-group-item-action py-1' style={{ borderInline: 'none', borderBottom: 'none' }}>
                                                    <input className='form-check-input me-1' type="checkbox" checked={false} readOnly/>
                                                    <label htmlFor="form-check-label">Garantías</label>
                                                </li>                                                
                                            </ul>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.vista === 'pagos' &&
                                    <div className="row m-0 mt-3 pb-5 d-flex justify-content-center animate__animated animate__fadeInUp">
                                        <div className="col-md-10 col-lg-10 col-xl-9 col-xxl-8 text-center">
                                            <table className='table table-hover mb-0' style={{ boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                                <thead>
                                                    <tr>
                                                        <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>No.</th>
                                                        <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Monto</th>
                                                        <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Fecha</th>
                                                        <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Estatus</th>
                                                        <th style={{ fontWeight: '500', backgroundColor: '#2EBF70', color: 'white' }}>Recibo</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>1</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>$1,000,000.00</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>15/09/2023</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>Pagado</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}><button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray', fontSize: '12px' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Recibo-1.pdf", "Recibo_1.pdf")}>Descargar</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>2</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>$1,200,000.00</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>22/01/2024</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>Pagado</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}><button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray', fontSize: '12px' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Recibo-2.pdf", "Recibo_2.pdf")}>Descargar</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>3</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>$550,000.00</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>27/02/2024</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>Pagado</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}><button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray', fontSize: '12px' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Recibo-3.pdf", "Recibo_3.pdf")}>Descargar</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>4</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>$550,000.00</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>27/03/2024</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>Pagado</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}><button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray', fontSize: '12px' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Recibo-4.pdf", "Recibo_4.pdf")}>Descargar</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>5</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>$550,000.00</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>15/04/2024</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>Pagado</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}><button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray', fontSize: '12px' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Recibo-5.pdf", "Recibo_5.pdf")}>Descargar</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>6</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>$550,000.00</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>15/11/2024</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>Pagado</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}><button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray', fontSize: '12px' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Recibo-6.pdf", "Recibo_6.pdf")}>Descargar</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>7</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>$550,000.00</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>13/12/2024</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}>Pagado</td>
                                                        <td style={{ backgroundColor: 'rgba(46, 191, 112, 0.1)' }}><button className='py-0' style={{ backgroundColor: 'transparent', border: 'none', color: 'gray', fontSize: '12px' }} onClick={() => this.handleDescargarClick("/docs/proyectos/33-Recibo-7.pdf", "Recibo_7.pdf")}>Descargar</button></td>
                                                    </tr>
                                                    <tr>
                                                        <td>8</td>
                                                        <td>$565,867.25</td>
                                                        <td>15/01/2025</td>
                                                        <td>Pendiente</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>9</td>
                                                        <td>$1,950,000.00</td>
                                                        <td>15/02/2025</td>
                                                        <td>Pendiente</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>10</td>
                                                        <td>$1,250,000.00</td>
                                                        <td>15/03/2025</td>
                                                        <td>Pendiente</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>11</td>
                                                        <td>$1,250,000.00</td>
                                                        <td>15/04/2025</td>
                                                        <td>Pendiente</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>12</td>
                                                        <td>$1,250,000.00</td>
                                                        <td>15/05/2025</td>
                                                        <td>Pendiente</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td>13</td>
                                                        <td>$284,132.75</td>
                                                        <td>15/06/2025</td>
                                                        <td>Pendiente</td>
                                                        <td></td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ borderBottom: 'none' }}>Total</td>
                                                        <td style={{ borderBottom: 'none' }}>$11,500,000.00</td>
                                                        <td style={{ borderBottom: 'none' }}></td>
                                                        <td style={{ borderBottom: 'none' }}></td>
                                                        <td style={{ borderBottom: 'none' }}></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                            }
                            {
                                this.state.vista === 'construccion' &&
                                    <>
                                    <div className="row m-0 mt-4 pb-4 animate__animated animate__fadeInUp">
                                        <div className="col-12">
                                            <div className="row m-0">
                                                <div className="col-12 text-center">
                                                    <h4 className='m-0' style={{ color: 'gray', fontFamily: 'Julius' }}>Avance semanal</h4>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div className='h-100' style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                                                        <div className='py-2' style={{backgroundColor: '#2EBF70', color: 'white', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                                            <p className='text-center m-0 tituloActividadesPorSemana d-sm-none' style={{ fontFamily: 'Julius' }}>Act. realizadas semana 9 - 14 Diciembre</p>
                                                            <p className='text-center m-0 tituloActividadesPorSemana d-none d-sm-block' style={{ fontFamily: 'Julius' }}>Actividades realizadas semana 9 - 14 Dic</p>
                                                        </div>
                                                        <div className='w-100' >
                                                            <ol className='py-1'>
                                                                <li>Se empezó compactación para firme de la parte de enfrente.</li>
                                                                <li>Se hicieron últimos registros.</li>
                                                                <li>Se termino detalle de coladeras del jardín trasero.</li>
                                                                <li>Se comenzaron a cubrir ranuras.</li>
                                                                <li>Limpieza de la parte de enfrente.</li>
                                                                <li>Armado de castillos.</li>
                                                                <li>Colocación de Vigueta y Bovedilla.</li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 mt-3">
                                                    <div className='h-100' style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
                                                        <div className='py-2' style={{backgroundColor: '#2EBF70', color: 'white', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                                            <p className='text-center m-0 tituloActividadesPorSemana d-sm-none' style={{ fontFamily: 'Julius' }}>Act. a realizar semana 16 - 21 Dic</p>
                                                            <p className='text-center m-0 tituloActividadesPorSemana d-none d-sm-block' style={{ fontFamily: 'Julius' }}>Actividades a realizar semana 16 - 21 Dic</p>
                                                        </div>
                                                        <div className='w-100' >
                                                            <ol className='py-1'>
                                                                <li>Detalles de ranuras.</li>
                                                                <li>Habilitado de castillos.</li>
                                                                <li>Firme de la parte de enfrente.</li>
                                                            </ol>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row m-0 mt-5">
                                            <div className="col-12 text-center">
                                                    <h4 className='m-0' style={{ color: 'gray', fontFamily: 'Julius' }}>Reporte Fotográfico de la Semana</h4>
                                                </div>
                                            </div>
                                            <div className="row m-0 mt-2 d-flex justify-content-center">
                                                <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                                                    <div className='d-flex justify-content-center align-items-center contenedorImgReporte' onClick={() => this.openModalImg(this.rutaImagen("/img/proyectos/33/rf-1.jpg"))} style={{ overflow: 'hidden', borderRadius: '5px', width: '100%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                                        <img src={this.rutaImagen("/img/proyectos/33/rf-1.jpg")} alt="imgAvance" style={{ borderRadius: '5px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center', cursor: 'pointer' }} />
                                                    </div>
                                                </div>
                                                <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                                                    <div className='d-flex justify-content-center align-items-center contenedorImgReporte' onClick={() => this.openModalImg(this.rutaImagen("/img/proyectos/33/rf-2.jpg"))} style={{ overflow: 'hidden', borderRadius: '5px', width: '100%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                                        <img src={this.rutaImagen("/img/proyectos/33/rf-2.jpg")} alt="imgAvance" style={{ borderRadius: '5px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center', cursor: 'pointer' }} />
                                                    </div>
                                                </div>
                                                <div className="col-6 col-sm-6 col-md-4 col-lg-4 mt-3 mt-sm-3 mt-md-0 col-xl-2">
                                                    <div className='d-flex justify-content-center align-items-center contenedorImgReporte' onClick={() => this.openModalImg(this.rutaImagen("/img/proyectos/33/rf-3.jpg"))} style={{ overflow: 'hidden', borderRadius: '5px', width: '100%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                                        <img src={this.rutaImagen("/img/proyectos/33/rf-3.jpg")} alt="imgAvance" style={{ borderRadius: '5px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center', cursor: 'pointer' }} />
                                                    </div>
                                                </div>
                                                <div className="col-6 col-sm-6 col-md-4 col-lg-4 mt-3 mt-sm-3 mt-md-3 mt-lg-3 mt-xl-0 col-xl-2">
                                                    <div className='d-flex justify-content-center align-items-center contenedorImgReporte' onClick={() => this.openModalImg(this.rutaImagen("/img/proyectos/33/rf-4.jpg"))} style={{ overflow: 'hidden', borderRadius: '5px', width: '100%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                                        <img src={this.rutaImagen("/img/proyectos/33/rf-4.jpg")} alt="imgAvance" style={{ borderRadius: '5px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center', cursor: 'pointer' }} />
                                                    </div>
                                                </div>
                                                <div className="col-6 col-sm-6 col-md-4 col-lg-4 mt-3 mt-sm-3 mt-md-3 mt-lg-3 mt-xl-0 col-xl-2">
                                                    <div className='d-flex justify-content-center align-items-center contenedorImgReporte' onClick={() => this.openModalImg(this.rutaImagen("/img/proyectos/33/rf-5.jpg"))} style={{ overflow: 'hidden', borderRadius: '5px', width: '100%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                                        <img src={this.rutaImagen("/img/proyectos/33/rf-5.jpg")} alt="imgAvance" style={{ borderRadius: '5px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center', cursor: 'pointer' }} />
                                                    </div>
                                                </div>
                                                <div className="col-6 col-sm-6 col-md-4 col-lg-4 mt-3 mt-sm-3 mt-md-3 mt-lg-3 mt-xl-0 col-xl-2">
                                                    <div className='d-flex justify-content-center align-items-center contenedorImgReporte' onClick={() => this.openModalImg(this.rutaImagen("/img/proyectos/33/rf-6.jpg"))} style={{ overflow: 'hidden', borderRadius: '5px', width: '100%', boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px' }}>
                                                        <img src={this.rutaImagen("/img/proyectos/33/rf-6.jpg")} alt="imgAvance" style={{ borderRadius: '5px', width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center center', cursor: 'pointer' }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row m-0 mt-5">
                                                <div className="col-12">
                                                    <p className='mb-0' style={{ fontFamily: 'Julius' }}>Avance de la construcción:</p>
                                                </div>
                                            </div>
                                            <div className="row m-0 mt-1">
                                                <div className="col-12">
                                                    <div className='' style={{ width: '100%', height: '40px', paddingTop: '7px', paddingBottom: '7px', paddingLeft: '1px', paddingRight: '1px', background: this.barraProgreso(27), border: '2px solid #212529', borderRadius: '5px' }}>
                                                        <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(27) }}>
                                                            27%
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row m-0 d-flex justify-content-center tarjetasFases">
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }}  onClick={() => this.openModalImg(this.rutaImagen("/img/proyectos/33/33-cimentacion.jpg"))}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Cimentación
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={this.rutaImagen("/img/proyectos/33/33-cimentacion.jpg")}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(100) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(90) }}>
                                                                                100%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }}  onClick={() => this.openModalImg(this.rutaImagen("/img/proyectos/33/33-cisterna.jpg"))}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Cisterna
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={this.rutaImagen("/img/proyectos/33/33-cisterna.jpg")}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(100) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(92) }}>
                                                                                100%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }}  onClick={() => this.openModalImg(this.rutaImagen("/img/proyectos/33/33-planta-baja.jpg"))}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Estructura Planta Baja
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={this.rutaImagen("/img/proyectos/33/33-planta-baja.jpg")}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(100) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(90) }}>
                                                                                100%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }}  onClick={() => this.openModalImg(this.rutaImagen("/img/proyectos/33/33-planta-alta.jpg"))}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Estructura Planta Alta y Segundo Nivel
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={this.rutaImagen("/img/proyectos/33/33-planta-alta.jpg")}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(30) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(30) }}>
                                                                                30%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }}  onClick={() => this.openModalImg(this.rutaImagen("/img/proyectos/33/33-instalacion-hidrosanitaria.jpg"))}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Instalación Hidrosanitaria
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={this.rutaImagen("/img/proyectos/33/33-instalacion-hidrosanitaria.jpg")}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(30) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(30) }}>
                                                                                30%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }}  onClick={() => this.openModalImg(this.rutaImagen("/img/proyectos/33/33-instalacion-electrica.jpg"))}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Instalación Eléctrica
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={this.rutaImagen("/img/proyectos/33/33-instalacion-electrica.jpg")}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(18) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(18) }}>
                                                                                18%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Instalación TV, Voz y Datos
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={"/img/sinImagen.jpg"}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(0) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(0) }}>
                                                                                0%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Instalación Gas
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={"/img/sinImagen.jpg"}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(0) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(0) }}>
                                                                                0%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }} onClick={() => this.openModalImg(this.rutaImagen("/img/proyectos/33/33-albanilerias.jpg"))}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Albañilerías
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={this.rutaImagen("/img/proyectos/33/33-albanilerias.jpg")}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(40) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(40) }}>
                                                                                40%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }} onClick={() => this.openModalImg(this.rutaImagen("/img/proyectos/33/33-acabados.jpg"))}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Acabados
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={this.rutaImagen("/img/proyectos/33/33-acabados.jpg")}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(5) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(5) }}>
                                                                                5%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Fachada
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={"/img/sinImagen.jpg"}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(0) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(0) }}>
                                                                                0%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Cancelería
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={"/img/sinImagen.jpg"}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(0) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(0) }}>
                                                                                0%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Lamparas y Accesorios Eléctricos
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={"/img/sinImagen.jpg"}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(0) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(0) }}>
                                                                                0%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Muebles de Baño y Accesorios
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={"/img/sinImagen.jpg"}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(0) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(0) }}>
                                                                                0%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Jardinería
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={"/img/sinImagen.jpg"}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(0) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(0) }}>
                                                                                0%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Carpintería
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={"/img/sinImagen.jpg"}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(0) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(0) }}>
                                                                                0%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                                <div className="col-sm-6 col-md-4 col-xl-4 col-xxl-3 tarjetaFase px-3 py-3">
                                                    <Card variant='outlined' style={{ borderRadius: '5px', height: '100%' }}>
                                                        <CardActionArea style={{ height: '100%' }} onClick={() => this.openModalImg(this.rutaImagen("/img/proyectos/33/33-extraordinarios.jpg"))}>
                                                            <CardContent style={{ backgroundColor: '#212529' }} className="py-2 px-2">
                                                                <Typography gutterBottom variant='h6' component="div" className='text-center m-0' style={{ color: 'white', fontWeight: '200', fontSize: '14px', fontFamily: 'Julius', whiteSpace: 'nowrap' }}>
                                                                    Extraordinarios
                                                                </Typography>
                                                            </CardContent>
                                                                <CardMedia
                                                                    component="img"
                                                                    width="100"
                                                                    image={this.rutaImagen("/img/proyectos/33/33-extraordinarios.jpg")}
                                                                    alt="imgFase"
                                                                    className='imagenTarjeta'
                                                                />
                                                            <CardContent className='pt-0 px-0 pb-0' style={{ height: '100%' }}>
                                                                <div className="row p-0">
                                                                    <div className="col-12 p-0">
                                                                        <div className='' style={{ width: '100%', height: '20px', padding: '3px', background: this.barraProgreso(20) }}>
                                                                            <p className='p-0 m-0 porcentaje text-center' style={{ position: 'relative', left: this.porcentaje(20) }}>
                                                                                20%
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-2 px-3" style={{ borderTop: '1px solid black' }}>
                                                                    <div className="col-12">
                                                                        <p style={{ fontWeight: 'bold', fontSize: '12px' }} className='m-0'>Descripción</p>
                                                                        <p style={{ fontSize: '12px', textAlign: 'justify' }}>-</p>
                                                                    </div>
                                                                </div>
                                                            </CardContent>
                                                        </CardActionArea>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </>

                            }
                            <Modal show={this.state.showModalImg} centered fullscreen onHide={this.hideModalImg}>
                                <Modal.Header closeButton style={{ border: 'none' }} className='bg-dark' closeVariant='white'/>
                                <Modal.Body className='d-flex bg-dark'>
                                    <img src={this.state.urlImgModal} alt="img" className='imgModal'/>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </main>
                </div> 
            }
            </>
         );
    }
}
 
export default MinaTrinidad;